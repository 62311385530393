<div id='globalSearchContainer' class="global-search-container fx-cover fx-pointer-events-auto">
    <div class="global-searc-content-container">
        <div class="global-search-input-field">
            <text-input #searchInputField
                [showClearButton]="true"
                icon="search-thin"
                [emitForInputChange]="true"
                placeholder="{{ searchPlaceholder }}"
                [debounce]="100"
                (updateText)="this.onSearchQueryChange.next( $event )"
                [autoFocus]="true"
                [transparentTextBox]="{ withPointerEvents: true }">
            </text-input>
        </div>

        <!-- document search result items -->
        <combined-search *ngIf="showDocumentSearchResults | async"></combined-search>
    </div>
</div>
